<template>
  <div class="about view">

    <h1 class="my-5">Rõõm sind näha!</h1>

    <h3 class="m-3">Nutitund.ee on loodud pakkumaks arendava sisuga mänge lastele</h3>

    <img class="img-fluid my-2" src="../assets/balloon.svg" alt="balloon">

    <h3 class="m-3">Keskkonna kasutamine on täiesti tasuta. Kasutaja loomine ei ole kohustuslik ning laste andmeid me ei
      küsi.</h3>
    <h3 class="m-3">Keskkond on loodud lihtsasti kasutatavana, kuid samas pidades olulisel kohal andmete
      turvalisust.</h3>

    <h6 class="mt-5">Nutitund.ee 2021</h6>
  </div>
</template>
